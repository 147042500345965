<template>
  <div class="p-6 border border-gray-300 rounded-xl flex-shrink-0">
    <div class="flex flex-col-reverse lg:flex-row lg:space-x-8">
      <div class="flex flex-col">
        <h2 class="font-sans text-4xl font-bold text-red">“</h2>
        <span class="font-sans font-semibold text-2xl text-red flex-1">
          {{ text }}
        </span>
        <div class="flex flex-row space-x-4 mt-4">
          <img
            v-if="personPictureUrl"
            :src="personPictureUrl"
            class="rounded-full w-12 h-12 object-cover"
          />
          <div class="flex flex-col text-sm">
            <strong class="font-sans leading-6">{{ personName }}</strong>
            <span>{{ personTitle }}</span>
          </div>
        </div>
      </div>
      <div v-if="$slots.extraContent" class="pb-4 lg:pb-0">
        <slot name="extraContent" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  props: {
    text: {
      type: String,
      default: null,
    },
    personName: {
      type: String,
      default: null,
    },
    personTitle: {
      type: String,
      default: null,
    },
    personPictureUrl: {
      type: String,
      default: null,
    },
  },
}
</script>
