<template>
  <div class="w-full">
    <div class="flex flex-row justify-between pb-6">
      <h3 class="text-xl font-bold text-sans items-center">
        {{ othersSay }}
      </h3>
      <div
        class="flex flex-row items-center w-12 justify-between"
        v-if="testimonials.length > 1"
      >
        <button
          aria-label="Forrige"
          class="w-2 h-2 arrow rotate-[135deg] disabled:border-gray-300"
          :disabled="index == 0"
          @click="index--"
        />
        <button
          aria-label="Næste"
          class="w-2 h-2 arrow -rotate-45 disabled:border-gray-300"
          :disabled="index == testimonials.length - 1"
          @click="index++"
        />
      </div>
    </div>
    <div class="flex overflow-x-hidden w-full sm:mx-0 sm:pl-0">
      <div
        v-for="(testimonial, key) in testimonials"
        ref="testimonialRef"
        :key="key"
        class="transition-all duration-100 flex"
        :class="
          fullWidth
            ? 'min-w-0 flex-[0_0_100%]'
            : 'w-[95%] sm:w-96 shrink-0 pr-6'
        "
      >
        <ArenaTestimonial
          class="flex-1"
          :person-name="testimonial.name ?? undefined"
          :text="testimonial.text ?? undefined"
          :person-picture-url="testimonial.image ?? undefined"
          :person-title="testimonial.jobTitle ?? undefined"
        >
          <template v-if="testimonial.extraContent" v-slot:extraContent>
            <div v-html="testimonial.extraContent" class="prose" />
          </template>
        </ArenaTestimonial>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { EventTestimonial } from '~/typesAuto/apicore/v2'

const props = withDefaults(
  defineProps<{
    testimonials: (EventTestimonial & { extraContent?: string })[]
    fullWidth: boolean
    othersSay?: string
  }>(),
  { othersSay: 'Det siger andre...' }
)
const testimonialRef = ref<HTMLDivElement[]>()
const index = ref(0)
watch(index, function (newIndex) {
  testimonialRef.value?.forEach((slide) => {
    slide.style.transform = `translateX(-${newIndex * 100}%)`
  })
})
</script>

<style scoped>
.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

button:disabled {
  border: solid gray;
  border-width: 0 3px 3px 0;
}
</style>
